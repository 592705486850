import Vue from 'vue'
import Router from "vue-router";
import store from './store'
Vue.use(Router)

import {
  USER_ROLE_GUEST,
  ADMIN_ROLE,


} from "constants";

USER_ROLE_GUEST
ADMIN_ROLE

const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
    routes: [
      
    
      {
        path: '/auth',
        name:'auth',
        component: () => import('@/views/pages/Index'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('@/views/pages/Login')
        }]
      },
      {
        path: '/',
        component: () => import('@/views/dashboard/Index'),
        beforeEnter: (to, from, next) => {
          
          if (localStorage.getItem('tokinn') && store.state.AdminInfo.authe==true) {
            next()
          } else {
            next({
              name: 'Login'
            });
          }
        },   
        children: [
          // {
          //   name: 'statistics',
          //   path: 'statistics',
          //   component: () => import('@/views/dashboard/Dashboard')
          // },
          {
            path: 'about',
            name:'about',
            component: () => import('@/views/dashboard/about'),
            
          },

          {
            path: 'Gallery',
            name:'Gallery',
            component: () => import('@/views/dashboard/Gallery'),
            
          },

          {
            path: 'GalleryCats',
            name:'GalleryCats',
            component: () => import('@/views/dashboard/GalleryCats'),
            
          },
          {
            path: 'team',
            name:'team',
            component: () => import('@/views/dashboard/team'),
            
          },
          //team

          {
            path: 'options',
            name:'options',
            component: () => import('./views/dashboard/options.vue'),
            
          },


          {
            path: '/',
            name:'articles',
            component: () => import('@/views/dashboard/articles'),
            
          },
          {
            path: 'messages',
            name:'messages',
            component: () => import('@/views/dashboard/messages'),
            
          },

          {
            path: 'artCats',
            name:'artCats',
            component: () => import('@/views/dashboard/artCats'),
            
          },
//artCats
       
        ]   
        
      },
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

